export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const adminRoot = "/app";
export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/#`;
export const servicePath = "https://api.coloredstrategies.com";

export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin,
};

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];

//extensions
export const GET_EXTENSION = "/extension";
export const ADD_EXTENSION = "/extension";
export const UPDATE_EXTENSION = "/extension/";
export const DELETE_EXTENSION = "/extension/";

//outbound rules
export const GET_RULE = "/patterns";
export const ADD_RULE = "/patterns";
export const UPDATE_RULE = "/patterns/";
export const DELETE_RULE = "/patterns/";

//cdr
export const GET_LAST_HUNDRED = "/cdr/getLastHundert";
export const CDR_SEARCH = "/cdr/search";

//login
export const LOGIN = "/login";

//settings
export const GET_USERS = "/users";
export const ADD_USER = "/users";
export const UPDATE_USER = "/users/";
export const DELETE_USER = "/users/";
export const GET_USER = "/users/";

//user profile
export const GET_ADMIN_USER = "/users/";
export const CHANGE_ADMIN_PASSWORD = "/passwords/";
export const UPDATE_ADMIN_USER = "/users/";
